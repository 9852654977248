<template lang="pug">
v-dialog(:value="true" max-width="500px" @input="$emit('close')")
  v-card
    v-card-title.d-flex.justify-space-between
      span Сменить роль {{email}}
    v-card-text
      v-select(
        v-model="roleId"
        :items="roles"
        item-value="id"
        item-text="name"
        :disabled="isLoading"
        :menu-props="{ offsetY: true }"
        outlined
      )
      v-row(align-end)
        v-col(cols="12" sm="6")
          v-btn(
            @click="$emit('close')"
            outlined
            block
          ) Отмена
        v-col(cols="12" sm="6")
          v-btn(
            @click="changeRole"
            block
            depressed
            color="primary"
            :loading="isLoading"
          ) Сохранить
</template>

<script>
import Spinner from '@/components/Spinner';
import AccountBindings from '@/views/accounts/components/AccountBindings';

import { mapGetters } from 'vuex';

export default {
  components: {
    Spinner,
    AccountBindings,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      roleId: null,
    };
  },

  computed: {
    ...mapGetters('ACCOUNTS', ['roles', 'listItem']),
    email() {
      return this.listItem(this.id)?.email;
    },
  },

  mounted() {
    this.roleId = this.listItem(this.id)?.roleId;
  },

  methods: {
    async changeRole() {
      this.isLoading = true;
      try {
        this.$store.dispatch('ACCOUNTS/changeRole', {
          id: this.id,
          roleId: this.roleId,
        });
        this.$notify({
          group: 'note',
          type: 'info',
          title: `Роль пользователя ${this.email} успешно изменена`,
        });
        this.$emit('close');
      } catch (error) {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Ошибка изменения роли пользователя ' + this.email,
          text: error,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
