<template lang="pug">
v-row
  v-col(cols="12")
    v-autocomplete(
      label="По организациям"
      :items="organizations"
      :value="value.orgs"
      outlined
      multiple
      chips
      deletable-chips
      item-value="id"
      item-text="name"
      @change="orgSearch = '', $emit('input', {orgs: $event})"
      :search-input.sync="orgSearch"
    )
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      orgSearch: '',
    };
  },
  computed: {
    ...mapGetters(['organizations']),
  },
};
</script>
